import React, { Component } from 'react';
import Link from '../../../../../core/Link';

import './index.css';

import bookingEngineIcon from './images/glyph_booking_engine.svg';
import propertyManagementSystemIcon from './images/glyph_pms.svg';
import channelManagerIcon from './images/glyph_channel_mgr.svg';
import supportIcon from './images/glyph_award.svg';

export class ProductPanel extends Component {
  render() {
    return (
      <div className="component product-panel">
        <div className="product-panel-inner-wrapper">
          <div className="product booking-engine">
            <div className="product-icon">
              <img src={bookingEngineIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>Booking Engine</h2>
              <div className="product-description">
                Receive more direct reservations and sell more items and
                packages with our customizable booking engine. The intuitive
                design incorporates features that curate the experience for each
                guest, and automatic rate adjustments ensure you maximize
                revenue. Simple to integrate with your website, it’s also
                mobile-responsive and ADA compliant.
              </div>
              <div className="product-learn-more">
                <Link
                  to="/products/booking-engine"
                  aria-label="Learn more about our booking engine"
                >
                  Learn more...
                </Link>
              </div>
            </div>
          </div>

          <div className="product property-management-system">
            <div className="product-icon">
              <img src={propertyManagementSystemIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>Property Management System</h2>
              <div className="product-description">
                Save time and increase productivity with the feature-rich
                property management system. We are the hospitality software
                provider with a system designed to automate and simplify your
                business with comprehensive tools and a full suite of reports
                enabling you to make data-driven business decisions.
              </div>
              <div className="product-learn-more">
                <Link
                  to="/products/property-management-system"
                  aria-label="Learn more about our property management system"
                >
                  Learn more...
                </Link>
              </div>
            </div>
          </div>

          <div className="product channel-manager">
            <div className="product-icon">
              <img src={channelManagerIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>Channel Manager</h2>
              <div className="product-description">
                Seamlessly synchronize your availability, restrictions, and
                rates with sites including Expedia, Booking.com, and Airbnb. Our
                integrated channel manager works in real-time to give you peace
                of mind when it comes to partnering with OTAs. Eliminate the
                need for a third-party product and say goodbye to
                double-bookings.
              </div>
              <div className="product-learn-more">
                <Link
                  to="/products/channel-manager"
                  aria-label="Learn more about our channel manager"
                >
                  Learn more...
                </Link>
              </div>
            </div>
          </div>

          <div className="product support">
            <div className="product-icon">
              <img src={supportIcon} alt="" />
            </div>
            <div className="product-content">
              <h2>24x7 Support</h2>
              <div className="product-description">
                Our customers love our knowledgeable, friendly Support Team.
                They not only know ThinkReservations inside and out, but they
                bring the unique added benefit of real-world lodging experience.
                Available 24/7, our US-based support can be accessed via our
                self-service online platform, email or phone and is included in
                your monthly subscription.
              </div>
              <div className="product-learn-more">
                <a
                  href="https://help.thinkreservations.com/"
                  aria-label="Learn more about our support"
                >
                  Learn more...
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductPanel;
