import React, { Component } from 'react';

import './index.css';

class ContactUsPanel extends Component {
  render() {
    return (
      <div className="component contact-us-panel">
        <h1 className="contact-us-panel-header">Let us know how we can help</h1>
        <div className="contact-us-panel-body">
          <div className="contact-us-panel-body-content contact-us-sales">
            <h2>Sales</h2>
            <p>
              Learn how ThinkReservations can help your business save time and
              increase revenue today. Contact our Sales & Education Team or sign
              up for a demo.
            </p>
            <div className="phone-wrapper">
              <a className="phone" href="tel:8777364195;ext=1">
                877-736-4195 x1
              </a>
            </div>
            <div className="email-wrapper">
              <a className="email" href="mailto:sales@thinkreservations.com">
                sales@thinkreservations.com
              </a>
            </div>
            <a className="button request-a-demo" href="/request-a-demo">
              Request a demo
            </a>
          </div>
          <div className="contact-us-panel-body-content contact-us-support">
            <h2>Support</h2>
            <p>
              Visit our{' '}
              <a href="http://help.thinkreservations.com/">support center</a>{' '}
              to find a comprehensive collection of articles and videos with
              answers to all of your questions. Or contact our Support Team via
              email or phone.
            </p>
            <div className="phone-wrapper">
              <a className="phone" href="tel:8777364195;ext=2">
                877-736-4195 x2
              </a>
            </div>
            <div className="email-wrapper">
              <a className="email" href="mailto:support@thinkreservations.com">
                support@thinkreservations.com
              </a>
            </div>
          </div>
          <div className="contact-us-panel-body-content contact-us-general">
            <h2>General contact</h2>
            <p>
              Interested in partnering with ThinkReservations or reaching out to
              us for press opportunities? Reach out to us and we will be in
              touch soon to see how we can work together.
            </p>
            <a className="email" href="mailto:info@thinkreservations.com">
              info@thinkreservations.com
            </a>
            <div className="thinkreservations-address">
              <div>
                <strong>ThinkReservations</strong>
              </div>
              <div>1700 7th Ave</div>
              <div>Ste 116 PMB 173</div>
              <div>Seattle, WA 98101</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUsPanel;
