import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Link from '../../core/Link';

import solutionsNavigation from '../../pages/solutions/navigation';

import './index.css';

export class MobileNavigation extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { id, className } = this.props;

    return (
      <ul className={`component mobile-navigation ${className}`} id={id}>
        <li className="tier-one actions">
          <ul>
            <li className="tier-two">
              <a
                className="bg-[#51b0d7] mb-1 text-center !text-white"
                href="https://manage.thinkreservations.com/"
              >
                Log In
              </a>
            </li>
            <li className="tier-two">
              <Link
                className="bg-[#e27d4e] text-center !text-white"
                to="/request-a-demo"
              >
                Request a demo
              </Link>
            </li>
            <li className="flex justify-between items-center">
              <span>
                <Link className="!pl-0" to="/company/contact-us">
                  Contact Us
                </Link>
              </span>

              <a className="phone !text-black" href="tel:8777364195">
                877-736-4195
              </a>
            </li>
          </ul>
        </li>
        <li className="tier-one products">
          <span className="tier-one products">Products</span>
          <ul>
            <li className="tier-two">
              <Link to="/products/booking-engine">Booking Engine</Link>
            </li>
            <li className="tier-two">
              <Link to="/products/property-management-system">
                Property Management System
              </Link>
            </li>
            <li className="tier-two">
              <Link to="/products/channel-manager">Channel Manager</Link>
            </li>
            <li className="tier-two">
              <Link to="/products/payment-processing">Payment Processing</Link>
            </li>
            <li className="tier-two">
              <Link to="/products/integrations">Integrations</Link>
            </li>
            <li className="tier-two">
              <Link to="/pricing">Pricing</Link>
            </li>
          </ul>
        </li>
        <li className="tier-one solutions">
          <span className="tier-one">Solutions</span>
          <ul>
            {solutionsNavigation.map(({ anchor, href }) => (
              <li key={href} className="tier-two">
                <Link to={href}>{anchor}</Link>
              </li>
            ))}
          </ul>
        </li>
        <li className="tier-one resources">
          <span className="tier-one">Resources</span>
          <ul>
            <li className="tier-two">
              <Link to="/resources/articles">Articles</Link>
            </li>
            <li className="tier-two">
              <Link to="/resources/webinars">Webinars</Link>
            </li>
            <li className="tier-two">
              <Link to="/resources/product-updates">Product Updates</Link>
            </li>
            <li className="tier-two">
              <a href="http://help.thinkreservations.com">Support</a>
            </li>
          </ul>
        </li>
        <li className="tier-one company">
          <span className="tier-one">Company</span>
          <ul>
            <li className="tier-two">
              <Link to="/company/our-team">Our Team</Link>
            </li>
            <li className="tier-two">
              <Link to="/company/careers">Careers</Link>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}

export default MobileNavigation;
